@media only screen and (max-width: 900px) and (min-width: 200px) {

    nav.desktop-navbar {
        display: none;
    }

    section#Projects .PortPiece1,
    section.WorkExperience .PortPiece1 {
        margin: auto;
        padding: 2%;
        font-size: .5em;
        font-family: helvetica;
        width: 95%;
        height: auto;
        display: inline-block;
    }

    footer#footertext {
        font-size: .75em;
        padding: 0;
        text-align: center;
        height: 20px;
        width: auto;
        display: block;
        margin: 0;
        position: fixed;
    }

    h2.contactLinks a {
        text-decoration: none;
        color: black;
        font-size: .75em;
    }
 
    h1#HeaderText {
        font-size: 2em;
        margin-top: 50%;
    }

    div#projects-container {
        display: block;
    }

    a.portfolioLinks {
        width: auto;
    }

    .navbar ul, .desktop-navbar ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1px 1px;
        grid-template-areas: "navbartextAbout navbartextProjects navbartextExperience" "navbartextContact navbartextContact navbartextContact";
        text-align: center;
        row-gap: 25%;  
    }
      
      li.navbartextExperience { grid-area: navbartextExperience; }
      
      li.navbartextProjects { grid-area: navbartextProjects; }
      
      li.navbartextAbout { grid-area: navbartextAbout; }
      
      li.navbartextContact { grid-area: navbartextContact;
    text-align: center; }

div#AboutMeText{
    width: 82%;
    margin: auto;
    font-size: 3vw;
    padding: 5%;
    z-index: 5;
      border-radius: 9%;
}

div#AboutMeImage{
    width: 100%;
    height: 350px;
    margin: auto;
    font-size: 1em;
    background-position: bottom;
    background-size: contain;

}

    body section#Projects,
    body section.WorkExperience {
        width: auto;
        margin: auto;
        margin-top: 0;
        height: auto;
        display: block;

    }

    body {
        font-size: 200em
    }

    nav.navbar, nav.desktop-navbar {
        height: 120px;
        padding: 0;
        margin: auto;
    }

    nav .navbartext,
    #footertext {
        font-size: 14pt;
        padding: 0;
        text-align: center;
        height: 20px;
    }

    section.BioSection {
        display: inline-block;
        width: auto;
        margin-top: -5%;
        padding: 12% 0 0%;
        height: 80%;
        border-radius: 0%;;
          }

    div p {
        line-height: 1.75;
    }

    div.projectDescription{
        line-height: 1.5;
        font-size: 100%;;
    }

    div.carousel{
        width: 100%;
        display: grid;
        grid-template-columns: .25fr 2fr .25fr;
        margin: auto;
        align-items: center;
    }

}

@media only screen and (min-width: 500px) and (max-width: 700px) {
   
    div#AboutMeImage {
        margin: auto;
        display: inline-block;
        text-align: center;
        border: none;
        width: 100%;
        height: 100%;
        clip-path: inset(10px 20px 30px 40px);
    }
   
    nav.navbar, nav.desktop-navbar {
        height: 70px;
        padding: 0;
        margin: auto;
        width: auto;
    }

    section.BioSection {
        background-color: #FFFFFF;
        width: 100%;
        padding: 2%;
        margin: auto;
         background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    

  
    .navbar ul,.desktopnavbar ul {
        display: flex;
        height: auto;
        margin: auto;
        padding: 5px;
    }

    div p {
        line-height: 1.25;
        font-size: 1.5em;
    }
}

@media only screen and (min-width: 700px) and (min-height: 900px) {
   section.Biosection{
       display: block;
   }

   div#AboutMeImage {
    width: 100%;
    margin: auto;
    height: 50vh;
    font-size: 1em;
    background-position: top;
    background-size: contain;
}
}

@media only screen and (aspect-ratio: 256/192) { 
    body section div#AboutMeText{
        width: 95%;
        margin: auto;
        font-size: large;
        padding: 3%;
        height: cover;
    }

    body section div#AboutMeImage{
        width: 100%;
        margin: auto;
        font-size: 1em;
        background-position: top right;
        background-size: contain;
    }
}

@media only screen and (aspect-ratio: 512/683) { 
    section.BioSection {
    display: inline-block;
    width: auto;
    padding: 0;
}

div#AboutMeText{
    margin: auto;
    font-size: 1em;
    padding: 3%;
}


section.BioSection{
    height: 78vh;
}
body  div#AboutMeImage {
    width: 100%;
    margin: auto;
    height: 100%;
    font-size: 1em;
    background-position: top right;
    background-size: contain;
}

div.projectDescription{
    line-height: 1.5;
    font-size: 100%;;
}

body section.WorkExperience {
    width: auto;
    margin: auto;
    margin-top: 0;
    height: auto;
    display: block;
}

section.WorkExperience .PortPiece1 {
    margin: 0px 1%;
    padding: 2%;
    font-size: 10pt;
    font-family: helvetica;
    width: 25%;
    height: auto;
    display: inline-block;
}


}

@media only screen and (min-width: 1200px) and (min-height: 900px){
    body section.BioSection{
        height: auto;
    }
    body  div#AboutMeImage {
        width: 100%;
        margin: auto;
        height: 100%;
        font-size: 1em;
        background-position: top right;
        background-size: contain;
    }

    p#AboutMeText-para {
        width: 100%;
           }

    body  div#AboutMeText {
        font-size: 1.5em;
        line-height: 3em;
        background-position: top right;
        background-size: 78%;
    }
}

@media only screen and (min-width: 900px){
    body section.BioSection{
        height: auto;
    }

    p#AboutMeText-para {
        width: 95%;
        text-align: justify;
        font-size: 1em;
        padding: 0%;
    }

    section.WorkExperience {
        margin: 0px 1%;
        padding: 2%;
        font-size: 10pt;
        font-family: helvetica;
        width: auto;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr ;
        grid-template-rows: 0.25fr 1.25fr;
        grid-template-areas:
    "WEHeadline WEHeadline WEHeadline WEHeadline"
    ". . . ."
    ". . . .";
    }


    .WEHeadline { 
        grid-area: WEHeadline; 
        box-shadow: 2px 2px 2px grey;
        background: teal;
    color: white;
    padding: 1%;
    width: 50%;
    margin: auto;
    font-size: 3em;
    border-radius: 5px 25px;
}

.workExperienceBlock {
    margin: auto;
    padding: 5%;
    margin-top: 0;
    font-family: helvetica;
    width: auto;
    height: auto;
    display: block;
    vertical-align: top;

}
    

div#AboutMeImage {
        width: 100%;
        margin: auto;
          font-size: 1em;
        background-position: top right;
        background-size: contain;
    }

    p#AboutMeText-para {
        width: 100%;
           }

    body  div#AboutMeText {
        font-size: 1.25em;
        line-height: 1.25em;
        background-position: top right;
        background-size: 78%;
    }

    body section#Projects {
        width: auto;
        margin: auto;
        margin-top: 0;
        height: auto;
        display: block;

    }

    nav.navbar {
        display: none;
    }

    ul#navList {
        margin: -7px;
    }


    body div#Port {
        width: 90%;
        margin: auto;
        margin-left: 0;
        margin-top: 0;
        height: auto;
        display: block;

    }

    nav .navbartext,
    #footertext {
        font-size: 15pt;
        padding: 0;
        text-align: center;
    
    }

    nav {
        height: 20px;
    }



    section#Projects .PortPiece1 {
        margin: auto;
        margin-top: 0;
        padding: 2%;
        font-size: 9pt;
        font-family: helvetica;
        width: 100%;
        height: auto;
        display: inline-block;

    }


    input[type="submit"],
    a#profile-link,
    a.portfolioLinks {
        background-color: rgb(185, 87, 2);
        color: #FFFFFF;
        padding: 25px;
        margin: 2%;
        display: inline-block;
        font-size: 100%;
        height: 20px;
        width: auto;
        line-height: 1em;
    }



   
    #AboutMeText {
        margin: 0;
        margin-right: 0;
        padding: 10px;
        display: inline;
        font-size: 1em;

    }

    div.projectDescription {
        grid-area: projectDescription;
    font-size: 100%;
    text-align: justify;
    }

    #AboutMeText-para {
        width: 95%;
        text-align: justify;
        font-size: 1em;
    }

    nav.navbar, nav.desktop-navbar {
        width: auto;
        height: 10px;
        text-align: center;
        z-index: 10;
        margin: auto;
    }

    nav .navbartext,
    #footertext {
        font-size: 15pt;
        padding: 0;
        text-align: center;
    }


    section.Contact {
        background-color: rgba(138, 249, 255, 0.4);
        width: auto;
        margin: auto;
        margin-top: 0;
        height: auto;
        padding: 5%;
        display: block;
    }



}


/* MEDIA QUERY ABOVE */

/* CSS BELOW */

.container{
    margin: auto;
    width: 100%;
    text-align: center;
}


.menuHeader{
    font-size: 2em;
    margin-top: 0;
}


.fade-in {
    animation: fadeIn ease 3s;
    -webkit-animation: fadeIn ease 3s;
    -moz-animation: fadeIn ease 3s;
    -o-animation: fadeIn ease 3s;
    -ms-animation: fadeIn ease 3s;
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
}


@-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }


  @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
              transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  

  nav {
 background-color: #FFFFFF;
}



.Projects{
    margin: auto;
    width: 100%;
    display: block;
    text-align: center;
}

.carousel{
    width: 90%;
    display: grid;
    grid-template-columns: .25fr 1fr .25fr;
    margin: auto;
    align-items: center
}

.image-slide {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr auto auto .25fr;
    grid-gap: 1px 1px;
    gap: 1px 1px;
    grid-template-areas:
        "projectTitle projectTitle projectTitle projectTitle"
        ". project-image project-image ."
        "projectDescription projectDescription projectDescription projectDescription"
        ". projectButtons projectButtons .";
    font-size: small;
    width: 100%;
    margin: auto;
       -webkit-animation: fadeIn ease 3s;
    -moz-animation: fadeIn ease 3s;
    -o-animation: fadeIn ease 3s;
    -ms-animation: fadeIn ease 3s;
    transition: fadeOut 1s ease-in-out;
    -webkit-transition: fadeOut 1s ease-in-out;
    -o-transition: fadeOut 1s ease-in-out;
}

@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;} 
 } 

 footer{
     background-color: #FFFFFF;
     bottom: 0;
     margin: auto;
     width: 100%;
     position: fixed;
 }

.projectTitle {
    grid-area: projectTitle;
font-size: 2em;
}

.project-image {
    grid-area: project-image;
    width: 100%;
}

.projectDescription {
    grid-area: projectDescription;
font-size: 50%;
text-align: center;
}

.projectButtons {
    grid-area: projectButtons;
}



#AboutMeText-para {
    width: 95%;
    text-align: justify;
    font-size: 5vw;
    padding: 5%;
}

ul {
    list-style: none;
    display: flex;
    padding: 0;
    height: auto;
    margin: auto;
    width: 100%;
}



label {
    font-family: helvetica;
    text-align: left;
    padding: 0 2% 2% 0;
    width: 30%;
}

#footertext {
    font-size: 1em;
    padding: 1%;
    text-align: center;
}

#HeaderText {
    text-shadow: 2px 2px 5px black;
    font-size: 3em;
    margin: auto;
    margin-top: 35vh;
}

.navbar, .desktop-navbar {
    margin: 0;
    background-color: whitesmoke;
    width: auto;
    padding: 2%;
    position: sticky;
    top: 0;
    height: 30px;
    text-align: center;
    z-index: 10;
    display: block;
    font-size: .6em;
}

.navbartext {
    color: rgb(0, 87, 91);
    text-decoration: none;
    margin: auto;
    position: relative;
    font-family: Helvetica;
    font-weight: lighter;
    padding: 1%;
    text-align: left;
    font-size: 16pt;
}


#footertext {
    color: black;
    text-decoration: none;
    margin: auto;
    position: relative;
    font-family: Helvetica;
    font-weight: lighter;
    font-size: auto;
    padding: 1%;
}

nav a {
    color: rgb(0, 87, 91);
    text-decoration: none;
    font-family: Helvetica;
}


body {
    font-family: Helvetica;
    margin: 0;
    padding: 0;
    font-size: 16px;
}

.welcome-section {
    margin: 0;
    padding: 1%;
    background-image: linear-gradient(rgba(124, 242, 248, 0.5), rgba(124, 242, 248, 1)), url(https://images.pexels.com/photos/249798/pexels-photo-249798.png);
    background-size: cover;
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-position: center;
    height: 88vh;
    text-align: center;
    font-size: 1.5em;
    color: rgb(255, 175, 106);
}

.workResp {
    text-align: left;
    line-height: 1.5;
    font-size: 1.5em;
}

li {
    text-align: left;

}

.hidden {
    display: none;
}

.BioSection {
    background-color: cadetblue;
    width: auto;
    position: relative;
    margin: 0;
    margin-top: -4%;
    padding: 4% 1% 10%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0% 0% 50% 50% / 10% 10% 0% 40%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#AboutMeImage {
    background: url("https://raw.githubusercontent.com/tsimpkins73/PORT-SITE/master/images/travisHeadshot_BW%20Artistic_h.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    margin-top: 0;
    margin-right: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    justify-content: center;
    font-size: .75em;
    -webkit-animation: slide-in-right 1s both;
            animation: slide-in-right 1s both;
}

.myPic {
    margin-right: 0;
    border: none;
    height: 100%;
    object-fit: cover;
    text-align: right;
    right: 0;
    float: right;
}

img {
    border: 2px solid white;
    height: auto;
}

#AboutMeText {
   
    margin: auto;
    padding: 5%;
    margin-top: 0;
    width: initial;
    display: inline-block;
    text-overflow: clip;
    font-size: 1.5em;
    animation: slide-in-left 1s both;
    z-index: 0;
    font-size: 1em;
    border-radius: 0% 0% 49% 50% / 10% 10% 40% 40%  ;
    background-color: teal;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.19);
    color: white;
    -webkit-animation: slide-in-left 1s both;
            animation: slide-in-left 1s both;
            z-index: 0;
            font-size: 1em;
}


.Contact {
    background-color: white;
    width: auto;
    margin: auto;
    margin-top: 0;
    height: auto;
    padding: 1%;
    display: block;
    text-align: center;
}



button {
    border: 0;
}



.WorkExperience {
    width: auto;
    box-sizing: 100%;
    margin: auto;
    margin-top: 0;
    height: 3%;
    padding: 3%;
    display: block;
    text-align: center;
    align-content: center;
    font-size: 1.5em;

}


#ContactForm {

    margin: auto;
    margin-top: 0;
    height: auto;
    padding: 3%;
    display: block;
    align-content: center;
    text-align: left;
    font-size: 1.5em;
}

#ContactForm textarea {
    width: 98%;
}

fieldset {
    border: none;
    margin-left: 0;
    width: auto;
    padding-left: 0;

}



input[type="submit"],
.portfolioLinks {
    width: 242px;
    margin: 3%;
    font-size: 16px;
    color: white;
    background-color: rgb(185, 87, 2);
    padding: 10px;
    display: inline-block;
    box-shadow: 1px 1px 2px black;
    font-size: 16pt;
}

input[type="submit"],
a#profile-link,
a.portfolioLinks {
    text-decoration: none;
    color: white;
    background-color: rgb(185, 87, 2);
    padding: 10px;
    display: inline-block;
    box-shadow: 1px 1px 2px black;
    font-size: 16pt;
}

input[type="text"] {
    width: 100%;
    height: 40px;
}


.PortPiece1 {
    margin: 0px auto;
    margin-top: 0;
    font-family: helvetica;
    width: auto;
    height: auto;
    display: block;
    vertical-align: top;
}

h2.contactLinks a {
    text-decoration: none;
    color: black;
}