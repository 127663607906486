@media only screen and (aspect-ratio: 1.43:1) {
    .Projects {
        width: auto;
        margin: auto;
        margin-top: -16%;
        height: auto;
        padding: 20% 3% 10%;
        display: block;
        z-index: 3;
        background-color: greenyellow;
        border-radius: 500% 1000%/100% 0%;
    }

    div.projectPreviewContainer {
        display: inline-block;
        background-color: teal;
        grid-column-gap: 1.5em;
        vertical-align: top;
        margin: 5%;
        padding: 9%;
        border-radius: 2% 5%;
        font-size: 1em;
        box-shadow:5px 5px 10px grey;
        animation: slide-in-top 1s both;
    }

  div div.projectPreviews {
        color: white;
        /* position: absolute; */
        width: auto;
        display: block;
        text-align: center;
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 1100px) {

    
div.projectPreviews {
    /* position: absolute; */
    width: auto;
    display: inline-grid;
    text-align: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
}
 
section.projectModal {
    width: 60%;
}

div.projectPreviewContainer {
    display: inline-grid;
    grid-column-gap: 1.5em;
    vertical-align: top;
    margin: 5%;
    padding: 3% 15%;
    border-radius: 2% 5%;
    font-size: 1em;
   }

}


.Projects {
    width: auto;
    margin: auto;
    margin-top: -16%;
    height: auto;
    padding: 20% 3% 10%;
    display: block;
    z-index: 3;
    background-color: greenyellow;
    border-radius: 0% 0% 50% 50% / 10% 10% 40% 0% ;
}

.ProjectsBlurred {
    display: inline-grid;
    text-align: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    z-index: -1;
    filter: blur(1.5rem);
    /* position: absolute; */
}



.projectPreviewContainer {
    display: inline-block;
    background-color: teal;
    grid-column-gap: 1.5em;
    vertical-align: top;
    margin: 5%;
    padding: 9%;
    border-radius: 2% 5%;
    font-size: 1em;
    box-shadow:5px 5px 10px grey;
    animation: slide-in-top 1s both;
}


img.modalProject-pic {
    width: 70%;
    height: auto;
}


img.project-pic {
    width: 90%;
    height: auto;
}

.projectModal {
    background-color: teal;
    color: white;
    margin: auto;
    padding: 5%;
    box-shadow: 0px 10px 5px grey;
    width: 90%;
    display: block;
    height: auto;
    text-align: center;
    z-index: 1;
    -webkit-animation-name: modal-animation;
    -webkit-animation-duration: 0.5s;
    animation-name: modal-animation;
    animation-duration: 0.5s;
}



.projectPreviews {
    color: white;
    /* position: absolute; */
    width: auto;
    display: block;
    text-align: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
}
